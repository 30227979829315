var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    staticClass: "container pt-0"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm.$options.components['profile-need-help'] ? [_c('profile-need-help')] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column justify-content-between mt-3"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between mb-2"
  }, [_c('h4', {
    staticClass: "mb-2 header-style"
  }, [_vm._v("Support Ticket")]), _c('b-button', {
    staticClass: "btn-outline-primary",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.show('modal-raise-service-request');
      }
    }
  }, [_vm._v(" Raise a Service Request ")])], 1), _c('div', {}, [[_c('div', [_c('b-modal', {
    attrs: {
      "hide-footer": "",
      "id": "modal-raise-service-request",
      "size": "lg",
      "title": "Raise a Service Request"
    }
  }, [_c('v-form', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "block": "",
      "cols": "12",
      "sm": "12",
      "md": "12"
    }
  }, [_c('p', {
    attrs: {
      "label": "Order #"
    }
  }, [_vm._v("Order # :" + _vm._s(_vm.orderNumber))])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6",
      "data-app": ""
    }
  }, [_c('label', {
    attrs: {
      "for": "serviceRequestIssueType"
    }
  }, [_vm._v("Select Issue Type")]), _c('b-form-select', {
    attrs: {
      "options": _vm.serviceRequestCategoryListItems,
      "text-field": "category",
      "value-field": "id",
      "size": "sm"
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('label', {
    attrs: {
      "for": "serviceRequestSubject"
    }
  }, [_vm._v("Issue")]), _c('b-form-input', {
    staticClass: "custom-select-sm",
    attrs: {
      "label": "Issue",
      "placeholder": "Enter Issue Related to"
    },
    model: {
      value: _vm.subject,
      callback: function callback($$v) {
        _vm.subject = $$v;
      },
      expression: "subject"
    }
  }, [_vm._v(_vm._s(_vm.subject))])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "12"
    }
  }, [_c('label', {
    attrs: {
      "for": "serviceRequestDescription"
    }
  }, [_vm._v("Description")]), _c('b-form-textarea', {
    attrs: {
      "id": "serviceRequestDescription",
      "rows": "3",
      "no-resize": ""
    },
    model: {
      value: _vm.description,
      callback: function callback($$v) {
        _vm.description = $$v;
      },
      expression: "description"
    }
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")])], 1), _c('b-col', {
    staticClass: "text-center"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-lg mr-3",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.hide('modal-raise-service-request');
      }
    }
  }, [_c('span', {
    staticClass: "button-text"
  }), _vm._v(" Cancel ")]), _c('button', {
    staticClass: "btn btn-primary btn-lg",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.createTicket() && _vm.$bvModal.hide('modal-raise-service-request');
      }
    }
  }, [_c('span', {
    staticClass: "button-text"
  }), _vm._v(" Submit Ticket ")])])], 1)], 1)], 1)], 1)], 1)]], 2)]), _vm.supportTicketListItems.length === 0 ? _c('span', [_c('div', {
    staticClass: "text-center mt-2 p-3 service-request"
  }, [_c('b-img', {
    staticClass: "mb-3",
    attrs: {
      "src": "img/empty_request.png"
    }
  }), _c('h3', [_vm._v("No Service Request Available")]), _c('button', {
    staticClass: "btn btn-outline-primary mt-3 mb-3",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.show('modal-raise-service-request');
      }
    }
  }, [_vm._v(" Raise a Service Request ")])], 1)]) : _c('span', [_c('b-table', {
    attrs: {
      "responsive": "",
      "head-variant": "light",
      "outlined": "",
      "fields": _vm.fields,
      "items": _vm.supportTicketListItems
    },
    scopedSlots: _vm._u([{
      key: "cell(ticketId)",
      fn: function fn(data) {
        return [_c('a', {
          staticClass: "ticketId",
          on: {
            "click": function click($event) {
              return _vm.viewTicketDetail(data.item);
            }
          }
        }, [_vm._v(" " + _vm._s(data.value) + " ")])];
      }
    }])
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }